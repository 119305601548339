body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Roboto', 'Segoe UI','Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.input-style {
  border: 1px solid #969696;
  border-radius: 3px;
  padding: 0.375rem 0.55rem;
  font-size: 14px;
}
.radio-one-line {
  margin-bottom: 0;
}

.form-control.is-invalid {
  background-image: none !important;
}
.radio-one-line .form-control {
  display: inline-block;
}
.radio-one-line .form-control .form-check:first-child {
  margin-right: 10px;
}
.image-box {
  width: 100%;
  display: inline-block;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  min-height: 25vh;
  margin-bottom: 10px;
}
.image-cavet {
  width: 100%;
  height: initial;
}
.loading-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  margin-top: 40vh;
  overflow: hidden;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.form-group {
  margin-bottom: 2%;
}
.nice-dates-day_month {
  display: none;
}
.nice-dates-day:before {
  background-color: #2A3790;
  color: #fff;
}
.nice-dates-day.-outside, .nice-dates-week-header_day {
  color: #5f5656;
}
.datepicker-input {
  pointer-events: none;
}
.datepicker-input.form-control:disabled,.datepicker-input.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
.nav-car-number a {
  color: #495057;
  font-size: 14px;
}
.nav-car-number a.active {
  color: #000!important;
}
.modal-content {
  border-radius: 15px;
  border: none;
}
.modal-backdrop.show {
  opacity: .6;
}
.modal-full-height .modal-content {
  height: 100%;
  border-radius: 0;
}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
  color: #dc3545;
}
.radio-one-line {
  margin-left: 10px;
}
.radio-one-line > .form-control {
  display: inline-block;
  width: 100%;
}
.radio-one-line > .form-control .form-check {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;
}
.btn-select-line {
  /* width: 100%; */
  display: inline-block;
}
.select-style {
  border: 1px solid #d6c2c2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all .3s ease;
  background: #ffffff;
}
.btn-select {
  border: 1px solid #d6c2c2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all .3s ease;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline;
  font-size: 16px!important;
  text-align: center;
}
.btn-select-full {
  width: 100%;
  display: inline-block;
}
.btn-select-full .btn-select {
  width: 100%;
}
.btn-select-line-year {
  width: 100%;
  display: inline-flex;
}
.btn-select-year {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.btn-select.active {
  border: 1px solid transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: rgb(43, 168, 224);
  color: #fff;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-switch-lg .custom-control-label {
  padding-left: 1.5rem;
  line-height: 1.7rem;
}
.custom-control-input:checked~.custom-control-label::before {
  color: #ffffff !important;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-switch-lg .custom-control-label::before {
  height: 2rem;
}
.custom-switch .custom-control-label::after {
  top: calc(.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}
.custom-switch-lg .custom-control-label::before {
  left: -2.25rem;
  width: 4rem;
  border-radius: 1.5rem;
}
.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(.75rem);
  transform: translateX(.75rem);
}
.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-switch-lg .custom-control-label::after {
  top: calc(.25rem + 3px);
  left: calc(-2.25rem + 4px);
  width: calc(2rem - 6px);
  height: calc(2rem - 6px);
  border-radius: 2rem;
}
.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(2rem);
}
.text-date-input {
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  border-radius: 10px;
}
.date-input-line {
  margin-left: 5%;
  margin-right: 5%;
}
.form-check-input:checked {
  background-color: #25806D !important;
  border-color: #25806D !important;
}

.sr-only {
  display: none;
}


@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}